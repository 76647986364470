import { Box, Checkbox, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface ICookieGroupProps {
  handleChange?: (enable: boolean) => void
  locked?: boolean
  title: string
  description: string
  checkboxValue?: boolean
}

const CookieGroup = (props: ICookieGroupProps) => {
  const { handleChange, locked, title, description, checkboxValue } = props
  const [isChecked, setIsChecked] = useState<boolean>(checkboxValue || false)

  const handleClick = () => {
    if (handleChange && !locked) {
      const enable = !isChecked
      setIsChecked(enable)
      handleChange(enable)
    }
  }

  return (
    <Box onClick={handleClick} sx={{ cursor: locked ? 'default' : 'pointer' }}>
      <Grid container alignItems='center'>
        <Grid item sm={1}>
          <Checkbox checked={locked ? true : isChecked} disabled={locked} />
        </Grid>
        <Grid item xs={10} sm={11} sx={{ cursor: 'pointer' }}>
          <Typography variant='h6' align='left'>
            <FormattedMessage id={title} />
          </Typography>
        </Grid>
        <Grid item sm={1} />
        <Grid item xs={10} sm={11} m='auto'>
          <Typography variant='body2' align='left'>
            <FormattedMessage id={description} />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CookieGroup
