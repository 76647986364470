/*
  This file contains functions to manage cookies for tracking services
 
  # Consent Mode v2 has been configured
    It manages different consent modes:
    - ad_storage: Enables storage (such as cookies) related to advertising. (Used for Google Ads)
    - ad_user_data: Sets consent for sending user data related to advertising to Google.
    - ad_personalization: Sets consent for personalized advertising. (Used for Remarketing in Google Ads)
    - analytics_storage: Enables storage (such as cookies) related to analytics
    - functionality_storage: Enables storage that supports the functionality of the website or app e.g. language settings.

    In our side, we are managing cookies as follows:
     - When Preference Cookies are enabled/disabled, functionality_storage is granted/denied.
        - Language
     - When Analytics Cookies are enabled/disabled, analytics_storage is granted/denied.
        - Google Analytics
        - Clarity (Controlled in GTM)
     - When Advertising Cookies are enabled/disabled, ad_storage, ad_user_data and ad_personalization are granted/denied.
        - Facebook Pixel
        - Google Ads
        - Linkedin
        - Salesforce Marketing Cloud

  More info related to consent modes: https://support.google.com/tagmanager/answer/13802165?hl=en
 */

import ReactGA from 'react-ga4'
import { config } from '../constants'
import TagManager from 'react-gtm-module'

declare global {
  interface Window {
    fbq: (action: string, event: string) => void
  }
}

const cookiesEnabled = !(
  window.origin.includes('localhost') ||
  window.origin.includes('dev-web') ||
  window.origin.includes('alpha-web')
)

const domain = window.location.hostname.includes('localhost') ? 'localhost' : '.gemvision.com'

interface IRemoveCookie {
  (name: string, options?: { path: string; domain: string }): void
}

interface ISetCookie {
  (name: string, value: string | boolean, options: { path: string; expires: Date }): void
}

type TagStateType = 'granted' | 'denied'

export const expiresDate = new Date(Date.now() + 2592000000)

/**
 * Accept or deny all cookies
 */
export const enableAllCookies = (enable: boolean, setCookie: ISetCookie) => {
  const tagConsent = enable ? 'granted' : 'denied'
  setCookie('hideCookiesBanner', true, { path: '/', expires: expiresDate })
  setCookie('analysisCookies', enable, { path: '/', expires: expiresDate })
  setCookie('marketingCookies', enable, { path: '/', expires: expiresDate })
  setCookie('functionalityCookies', enable, { path: '/', expires: expiresDate })
  ReactGA.gtag('consent', 'update', {
    analytics_storage: tagConsent,
    ad_user_data: tagConsent,
    ad_storage: tagConsent,
    ad_personalization: tagConsent,
    functionality_storage: tagConsent
  })
  updateFBCookies(enable)
}

/**
 * Track a page event with Facebook Pixel
 */
const trackPageEventFB = () => {
  window.fbq('track', 'PageView')
}

/**
 * Grant consent to track page with Facebook Pixel
 */
const grantConsentFB = () => {
  window.fbq('consent', 'grant')
  trackPageEventFB()
}

/**
 * Revoke consent to track page with Facebook Pixel
 */
const revokeConsentFB = () => {
  window.fbq('consent', 'revoke')
}

/**
 * Initialize tag manager to start collecting data.
 */
const initTagManager = () => {
  const tagManagerArgs = {
    gtmId: config.GOOGLE_TAG_MANAGER
  }
  TagManager.initialize(tagManagerArgs)
}

/**
 * Initialize GTM. Disabled by default. The consent must be granted to start tracking
 */
export const initializeGoogleTagManager = () => {
  ReactGA.gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied'
  })
  initTagManager()
}

/**
 * Delete GA cookies
 */
const deleteGACookies = (removeCookie: IRemoveCookie) => {
  const analyticsCookies = ['_ga', '_ga_FJKRRLFFFL', '_ga_1SD89TJ089']
  analyticsCookies.forEach((cookie) => {
    removeCookie(cookie, { domain, path: '/' })
  })
}

/**
 * Delete FB cookies
 */
const deleteFBCookies = (removeCookie: IRemoveCookie) => {
  removeCookie('_fbp', { domain, path: '/' })
}

/**
 * Grant or deny access to a specific event in TAG Manager
 */
const updateTagEvent = (cookie: string, state: TagStateType) => {
  const consentMap: Record<string, string> = {
    analytics_storage: 'analytics_storage',
    ad_user_data: 'ad_user_data',
    ad_storage: 'ad_storage',
    functionality_storage: 'functionality_storage',
    ad_personalization: 'ad_personalization'
  }
  const consentKey = consentMap[cookie]
  if (consentKey) {
    ReactGA.gtag('consent', 'update', { [consentKey]: state })
    if (consentKey === 'analytics_storage' && state === 'granted') {
      initTagManager()
    }
  }
}

export const updateMarketingCookies = (state: TagStateType, removeCookie: IRemoveCookie | null = null) => {
  updateTagEvent('ad_storage', state)
  updateTagEvent('ad_user_data', state)
  updateTagEvent('ad_personalization', state)
  updateFBCookies(state === 'granted', removeCookie)
}

export const updateAnalyticsCookies = (state: TagStateType, removeCookie: IRemoveCookie | null = null) => {
  updateTagEvent('analytics_storage', state)
  if (state === 'denied' && removeCookie) deleteGACookies(removeCookie)
}

export const updateFunctionalityCookies = (state: TagStateType) => {
  updateTagEvent('functionality_storage', state)
}

/**
 * Update the status of FB Cookies
 */
const updateFBCookies = (enable: boolean, removeCookie: IRemoveCookie | null = null) => {
  if (cookiesEnabled) {
    if (enable) {
      grantConsentFB()
    } else {
      revokeConsentFB()
      if (removeCookie) {
        deleteFBCookies(removeCookie)
      }
    }
  }
}
