import axios from 'axios'
import { Auth } from '@aws-amplify/auth'
import { Amplify } from '@aws-amplify/core'
import { ILogin, ISetCookies, IStripeInfo, IUserInfo } from '../interface'
import { Dispatch } from 'redux'
import { FormattedMessage } from 'react-intl'
import { STRIPE_ACCOUNT } from './index'
import { updateTTLUserId } from '../services'
import { config, BASE_URL_STRIPE, BASE_URL_STULLER } from '../constants'
import { isToday } from '../utils'
import ReactGA from 'react-ga4'
import { checkAccountData, updateStripeAccountData } from '../services/Stripe'
import { dispatchGetCartAfterLogin } from 'src/utils/cart'

Amplify.configure({
  region: config.REGION,
  userPoolId: config.USER_POOL_ID,
  userPoolWebClientId: config.APP_CLIENT_ID,
  mandatorySignIn: true,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  cookieStorage: {
    domain: config.DOMAIN,
    path: '/',
    secure: true
  }
})

// --- Action Type ---
export const LOGIN_ACCOUNT = 'LOGIN_ACCOUNT'
export const LOGOUT_ACCOUNT = 'LOGOUT_ACCOUNT'
export const LOGGINGIN = 'LOGGINGIN'
export const PENDING_PAYMENTS = 'PENDING_PAYMENTS'
export const IS_AUTH = 'IS_AUTH'

// --- Action ---
export const loginAccount = (
  dataForm: ILogin,
  setOpenLoading: (n: boolean) => void,
  setLoginError: (n: boolean) => void,
  setCookies: ISetCookies,
  popupCookie: string
): any => {
  return async (dispatch: Dispatch) => {
    try {
      const auth = await Auth.signIn(dataForm.username, dataForm.password)

      if (!auth.username) throw Error('Incorrect user')

      const request = await axios.get(`${BASE_URL_STULLER}/userAccountInfo`, {
        params: {
          userId: dataForm.username
        }
      })
      const user = request.data
      let stripeAccount = await getStripeAccountAfterLogin(user.Email)
      if (!stripeAccount.data && user) {
        stripeAccount = await createStripeUser(user)
      }
      await dispatchLoginData(dispatch, stripeAccount, user, setCookies, popupCookie)
      dispatchGetCartAfterLogin(user.BillToAccountObj, stripeAccount.data.id)
      dispatch({ type: IS_AUTH })
      window.dispatchEvent(
        new CustomEvent('sendNotification', {
          detail: { type: 'success', message: <FormattedMessage id='notification.loginSuccessfully' /> }
        })
      )
      setOpenLoading(false)
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === 'Incorrect username or password.') {
          window.dispatchEvent(
            new CustomEvent('sendNotification', {
              detail: { type: 'danger', message: <FormattedMessage id='notification.loginError' /> }
            })
          )
        } else {
          window.dispatchEvent(
            new CustomEvent('sendNotification', {
              detail: { type: 'danger', message: error.message }
            })
          )
        }
      } else {
        console.error('Unexpected error on loginAccount -', error)
      }
      setLoginError(true)
      setOpenLoading(false)
    }
  }
}

export const refreshAccount =
  (username: string, setCookies: ISetCookies, popupCookies: string) => async (dispatch: any) => {
    try {
      const request = await axios.get(`${BASE_URL_STULLER}/userAccountInfo`, {
        params: {
          userId: username
        }
      })
      const user = request.data
      const stripeAccount = await getStripeAccountAfterLogin(user.Email)
      await dispatchLoginData(dispatch, stripeAccount, user, setCookies, popupCookies)
      dispatch({ type: IS_AUTH })
    } catch (error) {
      console.log('Error on refreshAccount -', error)
      dispatch(logoutAccount())
    }
  }

const dispatchLoginData = async (
  dispatch: Dispatch,
  stripeAccount: { data: IStripeInfo },
  user: IUserInfo,
  setCookies: any,
  popupCookie: string
) => {
  ReactGA.gtag('event', 'login', { userId: user?.BillToAccount })
  if (checkAccountData(user, stripeAccount.data)) {
    stripeAccount = await updateStripeAccountData(user, stripeAccount.data)
  }
  const { temporary_user_id, date } = updateTTLUserId(stripeAccount.data.id)
  setCookies('temporary_user_id', temporary_user_id, { expires: date, path: '/' })
  setCookies('country_code', user.BillToAccountObj.Country.toLowerCase(), { path: '/' })
  dispatch({ type: STRIPE_ACCOUNT, payload: stripeAccount })
  dispatch({ type: LOGIN_ACCOUNT, payload: user })

  try {
    const result = await axios.get(`${BASE_URL_STRIPE}/pendingPayments?customerId=${stripeAccount.data.id}`)
    const pendingPayments = result.data
    if (pendingPayments && pendingPayments.length !== 0) {
      dispatch({
        type: PENDING_PAYMENTS,
        payload: pendingPayments
      })
      if (!popupCookie || !isToday(new Date(popupCookie))) {
        setCookies('popupDay', new Date().toDateString(), { path: '/' })
        window.dispatchEvent(new CustomEvent('showPendingSubscriptionPayment'))
      }
    }
  } catch (err) {
    console.error('Error on dispatchLoginData. Error:', err)
  }
}

export const logoutAccount = () => (dispatch: Dispatch) => {
  dispatch({ type: LOGOUT_ACCOUNT })
  Auth.signOut()
  window.dispatchEvent(new CustomEvent('cleanCartReducer'))
  return Promise.resolve()
}

export const getStripeAccountAfterLogin = async (email: string): Promise<any> => {
  try {
    const request = await axios.get(`${BASE_URL_STRIPE}/customer`, {
      params: {
        email
      }
    })
    return request
  } catch (error: any) {
    console.log('Error on getStripeAccountAfterLogin - ', error)
    throw error
  }
}

const createStripeUser = async (userData: any): Promise<any> => {
  try {
    const request = await axios.post(`${BASE_URL_STRIPE}/customer`, {
      userData
    })
    return request
  } catch (error: any) {
    console.log('Error on createStripeUser', error)
    throw error
  }
}

export const setLoggingin = (loggingin: boolean) => {
  return {
    type: LOGGINGIN,
    payload: loggingin
  }
}
